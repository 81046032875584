/* Booknow.css */

/* Header */
.booking-hd {
  text-align: center;
  margin-bottom: 50px;
}

/* Form Background and Border */
.booking-form {
  background-color: rgb(221, 219, 219);
  border-bottom: 1px solid rgb(177, 176, 176);
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin: 2rem auto;
  max-width: 800px;
}

/* Form Elements */
.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: #495057;
}

.form-group input,
.form-group .react-datepicker__input-container input,
.form-group .react-phone-number-input {
  width: 100%;
  padding: 0.75rem;
  border-radius: 4px;
  border: 1px solid #ced4da;
  font-size: 1rem;
  color: #495057;
}

.form-group input:focus,
.form-group .react-datepicker__input-container input:focus,
.form-group .react-phone-number-input:focus {
  border-color: #80bdff;
  outline: none;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.25);
}

/* Buttons */
.confirm-booking-btn {
  background-color: #fff;
  color: #1f5156;
  border: 2px solid #1f5156;
  font-weight: bold;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.confirm-booking-btn:hover {
  background-color: #1f5156;
  color: white;
  border: 2px solid #1f5156;
}

.confirm-booking-btn:focus {
  border: none;
}

.start-booking-btn {
  background-color: #1f5156;
  color: white;
  border: 2px solid #1f5156;
  font-weight: bold;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.start-booking-btn:hover {
  background-color: #fff;
  color: #1f5156;
  border: 2px solid #1f5156;
}

.start-booking-btn:focus {
  border: none;
}

/* Error Styling */
.error {
  width: 100%;
  color: #dc3545;
  text-align: center;
  font-size: 0.875rem;
}

/* Responsive Design */
@media (max-width: 768px) {
  .booking-form {
    padding: 1rem;
    margin: 1rem;
  }

  .booking-form h1 {
    font-size: 1.5rem;
  }

  .form-group label {
    font-size: 0.875rem;
  }

  .form-group input,
  .form-group .react-datepicker__input-container input,
  .form-group .react-phone-number-input {
    font-size: 0.875rem;
  }
}
