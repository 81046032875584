@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');
*{

  font-family: 'Nunito', sans-serif;
}

::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-thumb {
    background: linear-gradient(transparent, #1f5156);
    border-radius: 12px;
}

::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(#D7DBDD, #1f5156);
}

@media (max-width: 576px) {
  ::-webkit-scrollbar {
    width: 4px;
}

::-webkit-scrollbar-thumb {
    background: linear-gradient(transparent, #1f5156);
    border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(#D7DBDD, #1f5156);
}
}