
.contactPage{
position: relative;
min-height: 100vh;
/* padding: 50px 100px; */
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
/* background: url(${contactImg}); */
background-size: cover !important;
}

.contactPage .content{
    max-width: 800px;
    text-align: center;
}

.contactPage .content h2{
    font-size: 36px;
    font-weight: 500;
    color: #fff;
}

.contactPage .content p {
    font-weight: 300;
    color: #fff;
}

.mycontainer{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}

.contactInfo{
    /* background-color: red; */
    width: 30%;
    display: flex;
    flex-direction: column;
}

.mycontainer .contactInfo .box {
    position: relative;
    padding: 20px 0;
    display: flex;
}

.mycontainer .contactInfo .box .icon{
    min-width: 60px;
    height: 60px;
    background-color: #c8d8e4;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 22px;
    color: #1f5156;
}

.mycontainer .contactInfo .box .text{
    display: flex;
    margin-left: 20px;
    font-size: 16px;
    color: #000;
    flex-direction: column;
    font-weight: 300;
}

.mycontainer .contactInfo .box .text h3{
    font-weight: 500;
    color: #1f5156;
}

.contactForm{
    width: 40%;
    /* background-color: #c8d8e4; */
    padding: 40px;

}

.contactForm h2{
    font-size: 30px;
    color: #1f5156;
    font-weight: 500;
}

 .inputBox{
    /* background-color: red; */
    position: relative;
    width: 100% !important;
    margin-top: 10px;
}

.contact-input
{
    width: 100% !important;
    padding: 5px 0;
    font-size: 16px;
    margin: 10px 0;
    border: none;
    border-bottom: 2px solid #333;
    outline: none;
    resize: none;
}

.inputBox span{
    position: absolute;
    left: 0;
    padding: 5px 0;
    font-size: 16px;
    margin: 10px 0;
    pointer-events: none;
    transition: 0.5s;
    color: #666;
}

.inputBox input:focus ~ span,
.inputBox input:valid ~ span,
.inputBox textarea:focus ~ span,
.inputBox textarea:valid ~ span{
color: #1f5156;
font-size: 12px;
transform: translateY(-20px);
}

.inputBox input[type="submit"]
{
    width: 100px;
    background: #00bcd4;
    color: #fff;
    border: none;
    cursor: pointer;
    padding: 10px;
    font-size: 18px;
    background-color: #1f5156 !important;
}
.inputBox input,
.inputBox textarea{
    background-color: #fff !important;
}
@media (max-width:991px){
    .contactPage{
        padding: 50px;
    }

    .mycontainer{
flex-direction: column;
    }
    .contactInfo{
        margin-left: 0px !important;
        width: 100% !important;
        margin-bottom: 40px;
    }
    .contactForm{
        width: 100%;
    }

}


.contact-us-hd{
    color: #1f5156 !important;
}

.contact-us-para{
    color: #000 !important;
}