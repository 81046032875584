
.heading
{
    height: 65vh;

}
.video-background
{
    position: absolute;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -1;
}


.welcome-msg
{
    position: relative;
    text-align: center;
    /* font-family: 'Flamenco', cursive; */
    color: #fff;
    width: 70%;
    top: 50%;
    background-color: transparent;
    border: 10px rgb(139, 139, 139) double;
    margin: 30px;
    opacity: 1s;
    margin-top: 5%;
    
}
.welcome-msg h1
{
    font-size:3rem;
    font-weight: 100;
    letter-spacing: 5px;
    margin-bottom: 30px;
}
.welcome-msg p
{
    font-size: 2.5vh;
    font-weight: 1em;
    letter-spacing: 1px;
    margin-bottom: 30px;
    margin: 20px;
    

}
.btna
{
    font-size: 18px;
    color: rgb(0, 0, 0);
    margin: 5px;
    padding: 7px 22px;
    text-decoration: none;
    border: 1px solid #1f5156;
    color: #fff;
   
}
.btn-book
{
    background: #1f5156;
    color: #fff;
}
.btn-home
{
    background: #fff;
    color: #000;
}
.btn-book:hover{
    transition: .5s;
    color: white;
    border: white 3px solid;
}

.btn-home:hover{
    background:  #1f5156;
    transition: .5s;
    color: white;
}

@media (max-width:1147px) {
    .welcome-msg{
        margin-top: 5%;
    }
    .welcome-msg p{
        font-size: 14px;
    }
    .welcome-msg h1{
        font-size: 30px;
    }
}


@media (max-width:968px) {
    .welcome-msg{
        margin-top: 5%;
    }
    .welcome-msg p{
        font-size: 12px;
    }
    .welcome-msg h1{
        font-size: 30px;
    }
}

@media (max-width:620px) {
    .welcome-msg{
        margin-top: 5%;
    }
    .welcome-msg p{
        font-size: 10px;
    }
    .welcome-msg h1{
        font-size: 20px;
    }
    .btna{
        width: 150px;
        font-size: 15px;
    }
}



@media (max-width:492px) {
    .welcome-msg h1{
        font-size: 20px;
    }
    .btna{
        width: 120px;
        font-size: 10px;
    }
}




@media (max-width:444px) {
    .welcome-msg h1{
        font-size: 15px;
        margin-bottom:5px;

    }
    .btna{
        width: 100px;
        font-size: 8px;
    }
    .welcome-msg p {
        margin-top: 0px !important;
    }
}




@media (max-width:382px) {
    .welcome-msg h1{
        font-size: 15px;
        margin-bottom:5px;
    }
    .btna{
        width: 100px;
        font-size: 8px;
    }
    .welcome-msg p {
        margin-top: 0px !important;
    }
}



@media (max-width:356px) {
    .welcome-msg h1{
        font-size: 12px;
        margin-bottom:3px;
    }
    .btna{
        width: 80px;
        font-size: 8px;
    }
    .welcome-msg p {
        text-align: justify;
        margin-top: 0px !important;
        margin-bottom: 0px;
    }
    .welcome-msg{
        margin: 0px !important;
    }
}



@media (max-width:331px) {
    .welcome-msg h1{
        font-size: 12px;
        margin-bottom:3px;
    }
    .btna{
        width: 80px;
        font-size: 8px;
    }
    .welcome-msg p {
        text-align: justify;
        margin-top: 0px !important;
        margin-bottom: 0px;
        font-size: 8px;
    }
    .welcome-msg{
        margin: 0px !important;
    }
}

