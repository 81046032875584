.login-form-main{
    width: 100%;
    height: 100vh;
}

.login-form-login-div{
    margin: 0 auto !important;
    -ms-transform: translateY(10%);
     transform: translateY(10%);
     box-shadow: 5px 15px 40px rgb(236, 235, 235);
     width: 40%;
     padding: 40px !important;  
     /* padding-bottom: 20px !important; */
     /* margin-top: % !important; */
    
}

.login-form-input-fields{
    margin-top: 20px !important;
    width: 60%;
    font-size: 30px !important;

}


.login-form-login-heading{
    text-align: center;
}
.login-form-submit-btn{
    margin-top: 30px !important;
    width: 200px;
    height: auto;
    padding: 5px;
    background-color: #1f5156 !important;
    border-radius: 35px !important;
    font-size: 20px !important;
}
.design{
    margin-top: 20px;
}

.google, .facebook{
    margin-top: 30px !important;

    background-color: #fff !important;
    border:black 1px solid !important;
    color: rgb(95, 95, 95) !important;
    font-size: 15px !important;
    width: 200px!important;
    height: auto !important;
    padding-top: 5px !important;
    /* margin-right: 20px !important; */
    font-weight: 550 !important;
}

.google img, .facebook img{
    width: 30px;
    height: 30px;
    margin:10px;
}

@media only screen and (max-width: 900px) {
    .login-form-input-fields{
        width: 70% !important;
    }
  }


  
@media only screen and (max-width: 760px) {
    .login-form-input-fields{
        width: 70% !important;
    }
    .login-form-login-div{
        width: 80%;
    }
    .login-form-submit-btn{
        height: 40px;
        font-size: 15px !important;
    }
  }


  /* login FORM END */