/* Table styles */
.table {
    width: 100%;
    border-collapse: collapse;
  }
  button{
    width: 70px;
    margin: 5px;
    font-size: small;
    position: relative;
  }
  
  .table, th, td {
    border: 1px solid black;
  }
  
  th, td {
    padding: 8px;
    text-align: left;
  }
  
  .card-body {
    margin: 20px;
  }
  
  /* Pagination styles */
  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .pagination button {
    padding: 10px 20px;
    margin: 0 5px;
    border: none;
    background-color: #007bff;
    color: white;
    cursor: pointer;
  }
  
  .pagination button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .pagination span {
    align-self: center;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .table, th, td {
      font-size: 14px;
    }
  
    .pagination {
      flex-direction: column;
    }
  
    .pagination button {
      width: 100%;
      margin: 5px 0;
    }
  }
  
  @media (max-width: 480px) {
    .table, th, td {
      font-size: 12px;
    }
  }
  