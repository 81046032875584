/* Carousel */

.carousel-item{
    height:100vh;
}
.carousel-caption{
    position: absolute;
    top: 38%;
    text-transform: uppercase;
    width: 100%;
    right: 0;
    left: 0;
}

.carousel-caption h1{
    font-size: 3.8rem;
    font-weight: 700;
    letter-spacing: .3rem;
    text-shadow: .1rem .1rem .8rem black;
    padding-bottom: 1rem;
}

.carousel-caption h3{

    font-size: 2rem;
    text-shadow: .1rem .1rem .5rem #000;
    padding-bottom: 1.6rem;
}

.btn-lg{
    border-width: medium;
    border-radius: 0;
    font-size: 1.1rem;
}
.carousel-btn{
    background-color: #1f5156;
    outline: none !important;
    color: white !important;
    border: none !important;
    height: 50px;
    border-radius: 24px;
    padding:10px !important;
    width: 160px;
}

.carousel-btn:hover{
    transition: 0.5s !important;
    color: black !important;
    border: 2px solid #1f5156 !important;
}


@media (max-width:767px) {

    .carousel-caption h1{
        font-size: 2.3rem;
        letter-spacing: .15rem;
        padding-bottom: .5rem;
    }

    .carousel-caption h3{
        font-size: 1.2rem;
        padding-bottom: 1.2rem;
    }

    .btn-lg{
        font-size: 1rem;
    }
    
}