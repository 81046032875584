.navbar {
  /* background-color: #1f5156; */
  background-color: #ff0000;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 20;
}

.nav-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80px;
  max-width: 1500px;
}

.nav-logo {
  /* color: rgb(248, 165, 39); */
  color: #fff;
  align-items: center;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  flex-grow: 1;
}
.nav-logo:hover{
color: white !important;
}
.nav-menu {
  display: flex;
  list-style: none;
  text-align: center;
  margin-right: 2rem;
}

.nav-links {
  color: #fff;
  font-weight: 600 !important; 
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  border-bottom: 3px solid transparent;
}
.fa-code {
  margin-left: 1rem;
}

.nav-item {
  line-height: 40px;
  margin-right: 1rem;
}
.nav-item2 {
    line-height: 40px;
    margin-right: 1rem;
  }
.nav-links:hover{
  color: white;
}
.nav-item:after {
  content: "";
  display: block;
  height: 3px;
  width: 0;
  background: transparent;
  transition: width 0.7s ease, background-color 0.5s ease;
}

.nav-item2:after {
    content: "";
    display: block;
    height: 3px;
    width: 0;
    background: transparent;
    transition: width 0.7s ease, background-color 0.5s ease;
  }
  
.nav-item:hover:after {
  width: 100%;
  background: #c8d8e4;
}
.nav-item:hover{
  color: white !important;
}
/* .nav-item2:hover:after {
    width: 100%;
    background: green;
  } */

.nav-item .active {
  color: #c8d8e4;
  /* border: 2px solid #c8d8e4; */
  /* border-radius: 20px; */
  font-weight: bolder;
}

.nav-icon {
  display: none;
}
.login-and-signup-btn{
    border: #2b6777 2px solid;
}

@media screen and (max-width: 1003px) {
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-top: 1pxsolid #fff;
    position: absolute;
    top: 80px;
    left: -110%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #1f5156;
    left: 0px;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }
  .nav-item .active {
    color: #2b6777;
    border: none;
  }
  .nav-item2 .active {
    color: #2b6777;
    border: none;
  }
  .nav-links {
    padding: 1.5rem;
    width: 100%;
    display: table;
  }

  .nav-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #fff;
  }
}







.login-btn{
  border-radius: 20px;

background-color: green;
}

.signup-btn{
  border-radius: 25px;

  background-color: rgb(224, 193, 53);
  }

  .logout-btn{
    border-radius: 20px;
    background-color: rgb(160, 46, 46);
    }

    .bookings-btn{
    border-radius: 20px;
color:white !important;
      background-color: rgb(49, 129, 35);
      }