/* Main container for the form */
.main-content {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  /* Header styling */
  h1 {
    text-align: center;
    color: #333;
    font-family: 'Arial', sans-serif;
    margin-bottom: 20px;
  }
  
  /* Form styling */
  form {
    display: flex;
    flex-direction: column;
  }
  
  /* Form group styling */
  .form-group {
    margin-bottom: 15px;
  }
  
  /* Label styling */
  label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
    color: #555;
    font-family: 'Arial', sans-serif;
  }
  
  /* Input and select field styling */
  input[type="text"],
  input[type="number"],
  input[type="file"],
  select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
    box-sizing: border-box;
  }
  
  /* Placeholder styling */
  input::placeholder {
    color: #888;
  }
  
  /* Button styling */
  button {
    background-color: #28a745;
    color: #fff;
    border: none;
    padding: 15px 20px;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  button:hover {
    background-color: #218838;
  }
  
  /* Error and success message styling */
  .alert {
    padding: 15px;
    border-radius: 4px;
    margin-top: 20px;
    font-size: 16px;
    color: #fff;
  }
  
  .alert.success {
    background-color: #28a745;
  }
  
  .alert.error {
    background-color: #dc3545;
  }
  
  /* Responsive styling */
  @media (max-width: 600px) {
    .form-group {
      margin-bottom: 10px;
    }
  
    button {
      width: 100%;
      padding: 12px;
    }
  }
  
  .container
  {
    width:1000px;
  }

  