body {
    margin: 0;
    padding: 0;
}

.container {
    margin-top: 5%;
}

.containers .card {
    position: relative;
    border: none;
    margin: 10px;
    width: calc(28% - 20px); /* 3 cards per row, accounting for margin */
    box-shadow: 0 10px 20px rgba(31, 31, 31, 0.4);
}

.containers .card .icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.containers .card .icon img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensure the image covers the container */
}

.slide {
    width: 100%; /* Full width of the card */
}

.containers .card .slide {
    height: 200px; /* Adjust as needed */
    transition: 0.5s;
}

.containers .card .slide.slide1 {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    transition: .7s;
    transform: translateY(100px);
}

.containers .card:hover .slide.slide1 {
    transform: translateY(0px);
}

.containers .card .slide.slide2 {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
    transition: .8s;
    transform: translateY(-100px);
    box-shadow: 0 20px 40px rgba(0,0,0,0.4);
}

.containers .card:hover .slide.slide2 {
    transform: translateY(0);
}

.containers .card .slide.slide2::after {
    content: "";
    position: absolute;
    width: 30px;
    height: 4px;
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);
    background: #c8d8e4 !important;
}

.containers .card .slide.slide2 .content p {
    margin: 0;
    padding: 0;
    text-align: center;
    color: #414141;
}

.containers .card .slide.slide2 .content h3 {
    margin: 0 0 10px 0;
    padding: 0;
    font-size: 24px;
    text-align: center;
    color: #414141;
}

.content h3 {
    color: #1f5156 !important;
    font-weight: bold;
}

.card {
    border: none;
    margin: 10px;
    box-shadow: 0 10px 20px rgba(31, 31, 31, 0.4);
}

.myCards {
    display: flex;
    flex-wrap: wrap;
    gap: 20px; /* Space between cards */
    justify-content: space-between; /* Adjust spacing between cards */
}

.featured-heading {
    margin-bottom: 60px;
}

.links {
    text-decoration: none;
}
